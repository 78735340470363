// All the common styles must be here.

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 1rem;
  background: #f5f5f5;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  @include transition(color 0.5s ease);
  &:hover,
  &:focus,
  &:active {
    outline: none !important;
    text-decoration: none !important;
  }
}

img {
  max-width: 100%;
}

table {
  border-spacing: 0;
  border: 1px solid black;
  min-width: 100%;

  tr {
    :last-child {
      td {
        border-bottom: 0;
        text-align: center;
      }
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    text-align: center;
  }
}

.body-loader {
  @include body-loader;
}

.error-message {
  color: $red;
  font-size: 90%;
  vertical-align: top;
  &::before {
    content: "⚠";
    font-size: 1rem;
    margin-right: 5px;
    vertical-align: top;
  }
}

.border-bottom-heading {
  font-weight: 500;
  border-bottom: 1px solid rgb(158, 158, 158);
  padding-bottom: 8px;
}

.gradient-primary {
  @include linearGradient(#304ffe, #6200ea, right);
}

.round {
  padding: 3px 9px;
  border: 1px solid #000;
  border-radius: 50%;
  white-space: nowrap;
}

.position-relative {
  position: relative;
}

.hidden {
  display: none !important;
}

// React Select Style Overwrites Start
.select-md {
  .react-select__control {
    min-height: 56px;
  }
}
.select-sm {
  .react-select__control {
    min-height: 40px;
  }
}
.react-select__menu {
  z-index: 9 !important;
}
// React Select Style Overwrites End

// Custom Select Style Overwrites Start
.select-xs {
  height: 36px;
  .MuiOutlinedInput-inputMarginDense {
    padding-top: 8.5px;
    padding-bottom: 8.5px;
  }
}
// Custom Select Style Overwrites End

// Material table Style Overwrites Start
.MuiTable-root {
  thead {
    &.align-center {
      tr {
        th {
          text-align: center;
        }
      }
    }
  }
  tbody {
    &.align-center {
      tr {
        td {
          text-align: center;
        }
      }
    }
  }
  tfoot {
    &.align-center {
      tr {
        td {
          text-align: center;
        }
      }
    }
  }
  &.table-bordered {
    border: 1px solid #000;
    thead {
      tr {
        th {
          border-bottom: 1px solid #000;
          border-right: 1px solid #000;
        }
      }
    }
    tbody {
      tr {
        td {
          border-color: #000;
          border-width: 0 1px 1px 0;
          border-style: solid;
        }
      }
    }
    tfoot {
      tr {
        td {
          color: rgba(0, 0, 0, 0.87);
          border-color: #000;
          border-width: 0 1px 1px 0;
          border-style: solid;
        }
      }
    }
  }
}
// Material table Style Overwrites End

// Print Common Styles Start
@media print {
  .print-none {
    display: none !important;
  }
}
// Print Common Styles End
